<template>
  <div class="common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:账号管理') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:权限申请列表') }}</span>
      </template>
      <template slot="headerGroup">
        <el-input
          v-model="pageConfig.context"
          :placeholder="$t('dc:请输入账号名称')"
          style="width: 280px; margin-right: 10px"
          :clearable="true"
          @keyup.enter.native="queryApi"
          @clear="queryApi"
        >
          <el-button
            class="btn-black"
            type="info"
            slot="append"
            @click="queryApi"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="true"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :min-width="item.width"
          :align="item.align"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="other" v-if="item.key === 'status'">
              <span v-if="STATUS_ENUM.i18n[scope.row.status] == 'Pending'">{{
                $t('dc:待处理')
              }}</span>
              <span v-else-if="STATUS_ENUM.i18n[scope.row.status] == 'Approved'">{{
                $t('dc:审批通过')
              }}</span>
              <span v-else-if="STATUS_ENUM.i18n[scope.row.status] == 'Refuse'">{{
                $t('dc:审批驳回')
              }}</span>
            </div>
            <div class="other" v-else>
              <span>{{ scope.row[item.key] || '-' }}</span>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
  </div>
</template>
<script>
export default {
  name: 'AccessList',
  mixins: [$PCommon.TableMixin],
  data() {
    return {
      loading: false,
      tableData: [],
      pageConfig: {
        context: '',
      },
    }
  },
  computed: {
    STATUS_ENUM() {
      return {
        i18n: {
          Pending: this.$t('dc:待处理'),
          Approved: this.$t('dc:审批通过'),
          Refuse: this.$t('dc:审批驳回'),
          0: 'Pending',
          1: 'Approved',
          2: 'Refuse',
        },
      }
    },
    columnConfig() {
      return [
        {
          key: 'no',
          name: 'No.',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: false, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: null, // 默认宽度，像素
          align: 'left',
        },
        {
          key: 'username',
          name: this.$t('dc:账号名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
          align: 'left',
        },
        {
          key: 'groupName',
          name: this.$t('dc:用户组名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
          align: 'left',
        },
        {
          key: 'projectName',
          name: this.$t('dc:产品名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
          align: 'left',
        },
        {
          key: 'email',
          name: this.$t('dc:邮箱'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 160,
          align: 'left',
        },
        {
          key: 'agency',
          name: this.$t('dc:代理商'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
          align: 'left',
        },
        {
          key: 'client',
          name: this.$t('dc:客户'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
          align: 'left',
        },
        {
          key: 'updateTime',
          name: this.$t('dc:更新时间'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
          align: 'left',
        },
        {
          key: 'approver',
          name: this.$t('dc:审批人'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
        {
          key: 'status',
          name: this.$t('dc:状态'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
          align: 'center',
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.queryApi()
    },
    async queryApi() {
      this.loading = true
      let { status, data } = await $Api.access.getAccessApplicationList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.pageConfig.total = data.total
        this.tableData = data.list
        this.loading = false
      } else this.loading = false
    },
  },
}
</script>
